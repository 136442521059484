export const Skills = () => {
  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">

            <h2>Our Aims</h2>
            <h3>We only have one Aim:</h3>
            <ul>
                <li>To provide equitable access to quality education</li>
              </ul>

              <h2>Our Objectives</h2>
              <ul>
                <li>To provide funding for academic and vocational education</li>
                <li>To emphasize that education is the bedrock of a hopeful future.</li>
                <li>To emphasize that it takes a village to raise just one child.</li>
                <li>To emphasize that equitable access to quality education is a basic human right of every child.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
