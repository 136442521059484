import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import founderImg from "../assets/img/founder-img.png";
import inspirationImg from "../assets/img/inspiration-img.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      

    },
    
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Our Story</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link  eventKey="second">Our Projects</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Our People</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>

                    <Tab.Pane eventKey="first">

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
        <img src={projImg1} alt={projects[0].title} style={{ maxWidth: '50%', height: 'auto', borderRadius: '60px' }} />
    </div>

                      <h2>Who We Are</h2>
                      <p><span style={{ color: 'green' }}>T</span>he<span style={{ color: 'green' }}>K</span>asune<span style={{ color: 'green' }}>P</span>roject is an organisation whose purpose is to provide funding for equitable
access to quality education at identified levels. <span style={{ color: 'green' }}>T</span>he<span style={{ color: 'green' }}>K</span>asune<span style={{ color: 'green' }}>P</span>roject will continuously strive to
improve capacity for quality service delivery and mobilise resources to address the critical
issues that hinder the youth of Zambia from gaining equitable access to a quality education,
be it academic or vocational. Through further education, <span style={{ color: 'green' }}>T</span>he<span style={{ color: 'green' }}>K</span>asune<span style={{ color: 'green' }}>P</span>roject seeks to
empower young people realise their full potential as well as strengthen their capacity to
achieve sustainable economic levels that break the cycle of poverty. With providing access to
further education at its core, the <span style={{ color: 'green' }}>T</span>he<span style={{ color: 'green' }}>K</span>asune<span style={{ color: 'green' }}>P</span>roject takes a holistic approach in its pursuit to
empower young people to realise their full potential. The programmes meet recipients at their
point of need, dealing with issues that might impact negatively on their personal journeys
through education. High on the agenda is self care. There is no question that there can be no
success where one’s mental health is in crisis. The work of <span style={{ color: 'green' }}>T</span>he<span style={{ color: 'green' }}>K</span>asune<span style={{ color: 'green' }}>P</span>roject supports and
highlights the importance of a robust and resilient mental state, as this is the gateway to any
form of success. <span style={{ color: 'green' }}>T</span>he<span style={{ color: 'green' }}>K</span>asune<span style={{ color: 'green' }}>P</span>roject has been running since January 2021.</p>
                    </Tab.Pane>

                    <Tab.Pane eventKey="second">
                    <h2>Education - The Mayeba Fund</h2>
                    <p><span style={{ color: 'green' }}>T</span>he<span style={{ color: 'green' }}>K</span>asune<span style={{ color: 'green' }}>P</span>roject provides access to higher education by providing funding using an
equitable approach.  How funding is provided depends on the ambitions of the young person,
their basic formal education, school leaving results etc. Prospective students are then
supported in making goals that are realistic and achievable. <span style={{ color: 'green' }}>T</span>he<span style={{ color: 'green' }}>K</span>asune<span style={{ color: 'green' }}>P</span>roject supports
academic or vocational education as it is important that opportunities are reflective of
different abilities and ambitions. <span style={{ color: 'green' }}>T</span>he<span style={{ color: 'green' }}>K</span>asune<span style={{ color: 'green' }}>P</span>roject encourages families to participate on all
levels in supporting their loved one (including financial support) on the agreed educational
journey.</p>
               
                    <p> <span style={{ color: 'green' }}>T</span>he<span style={{ color: 'green' }}>K</span>asune<span style={{ color: 'green' }}>P</span>roject also provides funding for extra lessons in preparation for Grade 7 and 9
GCSE exams respectively. The Grade 7 certificate is the primary school leaving certificate,
while the Grade 9 certificate enables young people to continue their secondary school
education. Without obtaining the required level of points needed at each stage of key exams,
the educational journey for many young people ends at either 13 or 16 years of age
respectfully. Additionally, <span style={{ color: 'green' }}>T</span>he<span style={{ color: 'green' }}>K</span>asune<span style={{ color: 'green' }}>P</span>roject pays for extra tuition in preparation for Grade
12 GCSE exams, for 18 year olds. These are even more crucial as young people have come
this far and they cannot afford to leave secondary school without a full Grade 12 GCSE
Certificate. An interruption at any stage of this educational journey, could be a catalyst for
early marriages, unplanned pregnancies, mental health issues and a myriad of forms of
substance abuse, all this, already now painfully commonplace.</p>

<h2>Community Outreach – The Paulina Fund</h2>
                    <p><span style={{ color: 'green' }}>T</span>he<span style={{ color: 'green' }}>K</span>asune<span style={{ color: 'green' }}>P</span>roject works to raise awareness of social, economic and mental health issues that
affect the Youth and the different communities of which they are a part of, be it rural or urban
through our community outreach programmes. <span style={{ color: 'green' }}>T</span>he<span style={{ color: 'green' }}>K</span>asune<span style={{ color: 'green' }}>P</span>roject will engage and encourage
the community on programme planning, implementation and sustainability. This will include,
for instance, preparing business plans, business grants and forming cooperatives, to create
financial empowerment. <span style={{ color: 'green' }}>T</span>he<span style={{ color: 'green' }}>K</span>asune<span style={{ color: 'green' }}>P</span>roject will also focus on raising awareness of the
importance of a healthy mental state in different communities and highlight the benefits of
accessing psychosocial support in identified communities of Zambia. <span style={{ color: 'green' }}>T</span>he<span style={{ color: 'green' }}>K</span>asune<span style={{ color: 'green' }}>P</span>roject will
work in partnership with established specialist organisations and will play an active role in
disrupting the status quo on the stigma associated with mental health challenges.</p>
                    </Tab.Pane>

                    <Tab.Pane eventKey="third">
                      <h2>The Founder:</h2>
                      <h2>Ruth Kasune Banda</h2>
                      <img src={founderImg} alt="Header Img" style={{ display: 'block', marginLeft: 'auto',  marginRight: 'auto', width: '400px', height: '500px', borderRadius: '60px' }} />


                      <p><span style={{ color: 'green' }}>T</span>he<span style={{ color: 'green' }}>K</span>asune<span style={{ color: 'green' }}>P</span>roject is the result of a relentless self challenge of my social responsibilities that
started in early 1995, just after my 23 rd  birthday. That is a long time to sit on a vision that has
such urgency. <span style={{ color: 'green' }}>T</span>he<span style={{ color: 'green' }}>K</span>asune<span style={{ color: 'green' }}>P</span>roject is indeed a labour of love. However, my vision is simple.
My call to social action is urgent. My prayer is truly a sincere one: Let us work together, to
ensure our youth have equitable access to quality education. Education should never be only
for those who can afford it, it should be for everyone who hungers for it. Please join me in
my work and with God before us, let us educate the youth of our beloved Zambia!</p>

                      <h2>The Inspiration:</h2>
                      <h2>Rodwin Chilikwela Kasune</h2>
                      <img src={inspirationImg} alt="Header Img" style={{ display: 'block', marginLeft: 'auto',  marginRight: 'auto', width: '400px', height: '500px', borderRadius: '60px' }} />


                      <p>Rodwin Chilikwela Kasune was my maternal grandfather. He was a wholesome human being
who recognised the emancipatory and transformative power of tertiary education. His passion
for education has remained with me so many years after his death when I was 11 years old.
These charitable works are to honour his life and generosity of spirit and are a monumental
mark of his lasting legacy of kindness on my life.</p>

    
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
