import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";

export const Newsletter = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (status === 'success') clearFields();
  }, [status])

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
    email.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email
    })
  }

  const clearFields = () => {
    setEmail('');
  }

  return (
    <Col lg={12}>
    <div className="newsletter-bx wow slideInUp">
      <div className="text-center">
        <h1>The Reason I Am:</h1>
        <h4>
          I say to the young people... "When I help you make sense of your life, my life makes sense".
          That is the reason I am. What is the reason you are?
        </h4>
        <h2>What is the reason you are?</h2>
      </div>
    </div>
  </Col>
  

  )
}
